import * as React from "react"
import { graphql,navigate,Link } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const Entry = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="エントリーフォーム｜株式会社スタートアッププラス" />

      <div className="block xl:flex justify-between">

      <div id="pageTitle" className="relative xl:fixed w-full xl:w-48 h-auto xl:h-full text-white bg-mail bg-no-repeat bg-center bg-cover  z-20 ">
          <h1 className="font-Copperplate text-center text-2xl sm:text-3xl py-10">Entry</h1>
          <ul className="w-full text-center pb-5">
            <li className='mb-5'>
              <Link to="/recruit" className=''>
                採用情報
              </Link>
            </li>
            <li className='mb-5'>
              <Link to="/recruit#FAQ" className=''>
                採用FAQ
              </Link>
            </li>
          </ul>
        </div>

        <div id="main" className="static xl:ml-48 w-full">
          <div className=" w-11/12 max-w-[800px] mx-auto py-10 sm:py-10">

            <section id="gaiyou" className="mb-20">
              <h2 className="text-2xl border-b border-key-sand mb-5 py-3 ">
                エントリーフォーム
              </h2>
              <p className="mb-5">
              エントリーは、オンラインエントリーフォームより受け付けております。<br />
              エントリーフォームより必要事項をご入力の上、送信ボタンを押してください。
              </p>
              <form onSubmit={ () => {navigate("/requirement/entry_sent")}} action="https://docs.google.com/forms/u/0/d/e/1FAIpQLScyYamJaK2v8YqUO6VVDHJ48nyHkVmOO3gmCUvoCVATD6v2mg/formResponse"
                target="hidden_iframe">
                <div className="relative z-0 mb-10 w-full group">
                  <input type="text" name="entry.1767227870" id="name" 
                    className="block font-light py-2.5 px-0 w-full bg-transparent border-0 border-b border-key-sand appearance-none focus:outline-none focus:ring-0 focus:border-key-pink peer" 
                    placeholder=" " required />
                  <label htmlFor="name" className="absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-key-pink peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    氏名　<small className=" text-key-pink">※必須</small>
                  </label>
                </div>
                <div className="relative z-0 mb-10 w-full group">
                  <input type="text" name="entry.1874049526" id="name_kana" pattern="[ァ-ンヴー|　| ]+$"
                    className="block font-light py-2.5 px-0 w-full bg-transparent border-0 border-b border-key-sand appearance-none focus:outline-none focus:ring-0 focus:border-key-pink peer" 
                    placeholder=" " required />
                  <label htmlFor="name_kana" className="absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-key-pink peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    フリガナ<small className=" text-key-pink">※必須</small>
                  </label>
                </div>
                <div className="relative z-0 mb-10 w-full sm:w-1/3 group">
                  <input type="date" name="entry.1162645878" id="birthday"
                    className="block font-light py-2.5 px-0 w-full bg-transparent border-0 border-b border-key-sand appearance-none focus:outline-none focus:ring-0 focus:border-key-pink peer"
                    placeholder=" " required />
                  <label htmlFor="birthday" className="absolute duration-300 transform -translate-y-6 top-2 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-key-pink peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    生年月日　<small className=" text-key-pink">※必須</small>
                  </label>
                </div>
                <div className="relative z-0 mb-10 w-full sm:w-1/3 group">
                  <input type="tel" name="entry.743665258" id="phone" 
                    className="block font-light py-2.5 px-0 w-full bg-transparent border-0 border-b border-key-sand appearance-none focus:outline-none focus:ring-0 focus:border-key-pink peer" 
                    placeholder=" " required />
                  <label htmlFor="phone" className="absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-key-pink peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    電話番号　<small className=" text-key-pink">※必須</small>
                  </label>
                </div>
                <div className="relative z-0 mb-10 w-full group">
                  <input type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" name="entry.2129661886" id="email"
                    className="block font-light py-2.5 px-0 w-full bg-transparent border-0 border-b border-key-sand appearance-none focus:outline-none focus:ring-0 focus:border-key-pink peer" 
                    placeholder=" " required />
                  <label htmlFor="email" className="absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-key-pink peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    メールアドレス　<small className=" text-key-pink">※必須</small>
                  </label>
                </div>
                <div className="relative z-0 mb-10 w-full sm:w-1/3 group">
                  <input type="text" name="entry.556765801" id="zip" pattern="\d{3}-?\d{4}" className="block py-2.5 px-0 w-full text-gray-900 bg-transparent border-0 border-b border-key-sand appearance-none focus:outline-none focus:ring-0 focus:border-key-pink peer" 
                    placeholder=" " />
                  <label htmlFor="zip" className="absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-key-pink peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    郵便番号<small>（半角数字）</small>
                  </label>
                </div>
                <div className="relative z-0 mb-10 w-full group">
                  <input type="text" name="entry.1766130777" id="address" className="block py-2.5 px-0 w-full text-gray-900 bg-transparent border-0 border-b border-key-sand appearance-none focus:outline-none focus:ring-0 focus:border-key-pink peer" 
                    placeholder=" " />
                  <label htmlFor="address" className="absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-key-pink peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    住所
                  </label>
                </div>
                <div className="relative z-0 mb-10 w-full sm:w-1/3 group">
                  <select type="text" name="entry.2081832031" id="desired_occupation" 
                    className="block font-light py-2.5 px-0 w-full bg-transparent border-0 border-b border-key-sand appearance-none focus:outline-none focus:ring-0 focus:border-key-pink peer" 
                    placeholder=" " required >
                    <option value="" hidden>選択してください ▼</option>
                    <option value="データ分析スタッフ">データ分析スタッフ</option>
                    <option value="ソフトウェアエンジニア（経験者）">ソフトウェアエンジニア（経験者）</option>
                    <option value="ソフトウェアエンジニア（未経験可）">ソフトウェアエンジニア（未経験可）</option>
                  </select>
                  <label htmlFor="desired_occupation" className="absolute duration-300 transform -translate-y-6 top-2 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-key-pink peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    希望職種　<small className=" text-key-pink">※必須</small>
                  </label>
                </div>
                <p className="mb-3">
                最終学歴
                </p>
                <div className="ml-4 relative z-0 mb-10 w-full group">
                  <input type="text" name="entry.205065899" id="school" className="block py-2.5 px-0 w-full text-gray-900 bg-transparent border-0 border-b border-key-sand appearance-none focus:outline-none focus:ring-0 focus:border-key-pink peer" 
                    placeholder=" " required />
                  <label htmlFor="school" className="absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-key-pink peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    学校名　<small className=" text-key-pink">※必須</small>
                  </label>
                </div>
                <div className="ml-4 relative z-0 mb-10 w-full group">
                  <input type="text" name="entry.920895824" id="undergraduate" className="block py-2.5 px-0 w-full text-gray-900 bg-transparent border-0 border-b border-key-sand appearance-none focus:outline-none focus:ring-0 focus:border-key-pink peer" 
                    placeholder=" " />
                  <label htmlFor="undergraduate" className="absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-key-pink peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    学部・学科
                  </label>
                </div>
                <div className="ml-4">
                <p>在籍期間</p>
                <small>※在学中の場合は卒業見込み年月を選択</small>
                </div>
                <div className="ml-4 flex flex-wrap">
                  <div className="relative z-0 mb-2 sm:mb-10 w-full sm:w-1/3 group">
                    <input type="month" name="entry.1306589917" id="period_start"
                      className="block font-light py-2.5 px-0 w-full bg-transparent border-0 border-b border-key-sand appearance-none focus:outline-none focus:ring-0 focus:border-key-pink peer"
                      placeholder=" "  />
                    <label htmlFor="period_start" className="absolute duration-300 transform -translate-y-6 top-2 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-key-pink peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    </label>
                  </div>
                  <p className="m-1 sm:m-5">〜</p>
                  <div className="relative z-0 mb-10 w-full sm:w-1/3 group">
                    <input type="month" name="entry.337647604" id="period_end"
                      className="block font-light py-2.5 px-0 w-full bg-transparent border-0 border-b border-key-sand appearance-none focus:outline-none focus:ring-0 focus:border-key-pink peer"
                      placeholder=" " />
                    <label htmlFor="period_end" className="absolute duration-300 transform -translate-y-6 top-2 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-key-pink peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    </label>
                  </div>
                </div>
                <div className="relative z-0 mb-10 w-full group">
                  <textarea name="entry.363218432" id="jobinfo" rows="4" 
                    className="block font-light py-2.5 px-0 w-full bg-transparent border-0 border-b border-key-sand appearance-none focus:outline-none focus:ring-0 focus:border-key-pink peer" 
                    placeholder=" " />
                  <label htmlFor="jobinfo" className="absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-key-pink peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                  職務経歴
                  </label>
                  <p className="text-xs ">
                  職務経歴は、社名、部署名、在籍期間及び担当した職務や実績など、新しいものからできるだけ詳しくご記入ください。<br />
                  ※職務経歴書をお持ちの方はそのままコピーしていただいて構いません。<br />
                  職務経歴が確認できるサイトのURLがあればご記入ください。(Googleドライブ等)</p>
                  
                </div>
                <div className="relative z-0 mb-10 w-full group">
                  <textarea name="entry.676661501" id="qualification" rows="4" 
                    className="block font-light py-2.5 px-0 w-full bg-transparent border-0 border-b border-key-sand appearance-none focus:outline-none focus:ring-0 focus:border-key-pink peer" 
                    placeholder=" " />
                  <label htmlFor="qualification" className="absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-key-pink peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    お持ちの資格等
                  </label>
                </div>
                <div className="relative z-0 mb-10 w-full group">
                  <input type="text" name="entry.1550804240" id="desired_salary" 
                    className="block font-light py-2.5 px-0 w-full bg-transparent border-0 border-b border-key-sand appearance-none focus:outline-none focus:ring-0 focus:border-key-pink peer" 
                    placeholder=" " />
                  <label htmlFor="desired_salary" className="absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-key-pink peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    希望年収
                  </label>
                </div>
                <div className="relative z-0 mb-10 w-full group">
                  <input type="text" name="entry.1460641731" id="current_salary" 
                    className="block font-light py-2.5 px-0 w-full bg-transparent border-0 border-b border-key-sand appearance-none focus:outline-none focus:ring-0 focus:border-key-pink peer" 
                    placeholder=" " />
                  <label htmlFor="current_salary" className="absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-key-pink peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    現年収
                  </label>
                </div>
                <div className="relative z-0 mb-10 w-full sm:w-1/3 group">
                  <select type="text" name="entry.141248611" id="situation" 
                    className="block font-light py-2.5 px-0 w-full bg-transparent border-0 border-b border-key-sand appearance-none focus:outline-none focus:ring-0 focus:border-key-pink peer" 
                    placeholder=" " required >
                    <option value="" hidden>選択してください ▼</option>
                    <option value="正社員として就業中">正社員として就業中</option>
                    <option value="契約社員として就業中">契約社員として就業中</option>
                    <option value="派遣社員として就業中">派遣社員として就業中</option>
                    <option value="アルバイトとして就業中">アルバイトとして就業中</option>
                    <option value="フリーランス">フリーランス</option>
                    <option value="離職中">離職中</option>
                    <option value="その他">その他</option>
                  </select>
                  <label htmlFor="situation" className="absolute duration-300 transform -translate-y-6 top-2 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-key-pink peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    現在のご状況　<small className=" text-key-pink">※必須</small>
                  </label>
                </div>
                <div className="relative z-0 mb-10 w-full group">
                  <textarea name="entry.596635886" id="message" rows="4" 
                    className="block font-light py-2.5 px-0 w-full bg-transparent border-0 border-b border-key-sand appearance-none focus:outline-none focus:ring-0 focus:border-key-pink peer" 
                    placeholder=" " />
                  <label htmlFor="message" className="absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-key-pink peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    ご質問・スタートアッププラスヘのメッセージ
                  </label>
                </div>

                <div className="mb-10">
                  <strong>【個人情報の管理について】</strong>
                  <p className="p-2 text-sm">
                  ご応募の際にお送りいただく個人情報は、当社における採用選考の実施、その他採用活動に必要な連絡等の目的の範囲内で利用させていただきます。
                  その他個人情報の取扱いに関する詳細については、<br />
                  当社のプライバシーポリシー（<Link to="/privacy-policy" target="_blank">http://www.startup-plus.com/privacy-policy</Link>）をご確認ください。<br /> 
                  個人情報の管理について同意いただける場合は下記「同意する」にチェックを付けて下さい。
                  </p>
                  <label htmlFor="agree" className="p-2">
                    <input type="checkbox" name="form-agree[data][]" value="同意する" id="agree" required />
                    <span className="ml-2">同意する</span><small className=" text-key-pink">※必須</small>
                  </label>

                </div>

                  <button type="submit" className="text-white bg-key-sand hover:bg-key-pink focus:ring-4 font-medium rounded-lg text-sm w-full sm:w-1/2 px-5 py-2.5 text-center">送 信</button>
              </form>
              <iframe title="hidden_iframe" name="hidden_iframe" style={{display:'none'}}></iframe>
            </section>

          </div>
        </div>
      </div>

    </Layout>
  )
}

export default Entry

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
